import React from "react";
import { Link } from 'gatsby'

const Blogs1 = ({ posts }) => {
  return (
    <section className="blog section-padding sub-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                RECENT ARTICLES
              </h6>
              <h3 className="wow color-font">From our blogs.</h3>
            </div>
          </div>
        </div>
        <div className="row">
          {(posts || []).map(post => (
            <div className="col-lg-6" key={post.id}>
              <div className="item md-mb50 wow fadeInUp" data-wow-delay=".3s">
                <div className="img">
                  <img src={post.image} alt="" />
                </div>
                <div className="cont">
                  <div>
                    <div className="info">
                      <Link to="/blog/blog-dark" className="date">
                        <span>
                          <i>06</i> August
                        </span>
                      </Link>
                      <span>/</span>
                      <Link to="/blog/blog-dark" className="tag">
                        <span>WordPress</span>
                      </Link>
                    </div>
                    <h5>
                      <Link to={`/blog-details/${post.slug || post.id}`}>
                        {post.title}
                      </Link>
                    </h5>
                    <div className="btn-more">
                      <Link to={`/blog-details/${post.slug || post.id}`} className="simple-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs1;
