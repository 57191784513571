import React from "react";
import blog1Data from "data/blog1.json";
import Navbar from "components/Navbar/navbar";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const BlogDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Terms of Service"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="privacy-section mb-50">
              <h4>1. Acceptance of Terms</h4>
              <p>By accessing and using our services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>

              <h4>2. Description of Services</h4>
              <p>We provide digital services including but not limited to:</p>
              <ul className="mb-30">
                <li>Web development and design</li>
                <li>Digital marketing solutions</li>
                <li>Software development</li>
                <li>Consulting services</li>
              </ul>

              <h4>3. User Responsibilities</h4>
              <p>When using our services, you agree to:</p>
              <ul className="mb-30">
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Not engage in any unauthorized or illegal activities</li>
              </ul>

              <h4>4. Intellectual Property</h4>
              <p>All content, trademarks, and intellectual property on our platform are owned by us or our licensors. You may not use, copy, or distribute our content without explicit permission.</p>

              <h4>5. Payment Terms</h4>
              <p>For paid services:</p>
              <ul className="mb-30">
                <li>Payments are due as specified in service agreements</li>
                <li>All fees are non-refundable unless stated otherwise</li>
                <li>We reserve the right to modify pricing with notice</li>
              </ul>

              <h4>6. Limitation of Liability</h4>
              <p>We provide our services "as is" and make no warranties, express or implied. We shall not be liable for any indirect, incidental, or consequential damages.</p>

              <h4>7. Termination</h4>
              <p>We reserve the right to terminate or suspend access to our services at our sole discretion, without notice, for conduct that we believe violates these terms or is harmful to other users, us, or third parties.</p>

              <h4>8. Governing Law</h4>
              <p>These terms shall be governed by and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law provisions.</p>

              <h4>9. Changes to Terms</h4>
              <p>We may update these terms from time to time. Continued use of our services after changes constitutes acceptance of the new terms.</p>

              <h4>10. Contact Information</h4>
              <p>For questions about these terms, please contact us at:</p>
              <p>Email: legal@acmeways.com</p>
              <p>Address: Toronto, Ontario, Canada</p>

              <p className="mt-50"><small>Last updated: January 2024</small></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Vie - Blog Dark</title>
    </>
  )
}

export default BlogDark;
