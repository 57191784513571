import React from "react";
import { graphql } from "gatsby";
import Navbar from "components/Navbar/navbar";
import BlogDetails from "components/Blog-details/blog-details";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import blog1Data from "data/blog1.json";

const BlogDetailsTemplate = ({ params }) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  
  // Find the blog post using the ID from URL params
  console.log("params.id: ", params.id);
  const blogPost = blog1Data.find(post => post.id === parseInt(params.id));

  React.useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <BlogDetails blog={blogPost} theme="dark" />
      <Footer />
    </DarkTheme>
  );
};

export const Head = ({ params, pageContext }) => {
  // Option 1: File System Route API
  const id = params?.id || params?.["*"];
  // OR Option 2: gatsby-node.js
  // const id = pageContext.id;
  
  const blogPost = blog1Data.find(post => post.id === parseInt(id));
  return (
    <>
      <title>AcmeWays - {blogPost?.title || 'Blog Details'}</title>
    </>
  )
}

export default BlogDetailsTemplate; 