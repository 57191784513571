import React from "react";
import Navbar from "components/Navbar/navbar";
import BlogDetails from "components/Blog-details/blog-details";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import blog1Data from "data/blog1.json";
import PageHeader from "components/Page-header/page-header";

const BlogPost = ({ params }) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  
  const id = params?.id;
  const blogPost = blog1Data.find(post => post.id === parseInt(id));

  React.useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title={blogPost?.title || "Blog Details"}
        paragraph="All the most current news and events of our creative team."
      />
      {blogPost ? (
        <BlogDetails blog={blogPost} theme="dark" />
      ) : (
        <div className="text-center py-5">
          <h2>Blog post not found</h2>
        </div>
      )}
      <Footer />
    </DarkTheme>
  );
};

export const Head = ({ params }) => {
  const id = params?.id;
  const blogPost = blog1Data.find(post => post.id === parseInt(id));
  
  return (
    <>
      <title>AcmeWays - {blogPost?.title || 'Blog Post Not Found'}</title>
      <meta 
        name="description" 
        content={blogPost?.content?.intro?.substring(0, 160) || 'Blog post details'} 
      />
    </>
  )
}

export default BlogPost; 