import React from "react";
import blog1Data from "data/blog1.json";
import Navbar from "components/Navbar/navbar";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const BlogDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Privacy Policy"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="privacy-section mb-50">
              <h4>1. Information We Collect</h4>
              <p>We collect information that you provide directly to us, including when you:</p>
              <ul className="mb-30">
                <li>Create an account or profile</li>
                <li>Contact us for support or information</li>
                <li>Sign up for our newsletters or updates</li>
                <li>Use our services or make purchases</li>
              </ul>

              <h4>2. How We Use Your Information</h4>
              <p>We use the information we collect to:</p>
              <ul className="mb-30">
                <li>Provide and maintain our services</li>
                <li>Process your transactions</li>
                <li>Send you technical notices and support messages</li>
                <li>Communicate with you about products, services, and events</li>
                <li>Protect against fraudulent or illegal activity</li>
              </ul>

              <h4>3. Information Sharing</h4>
              <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
              <ul className="mb-30">
                <li>Service providers who assist in our operations</li>
                <li>Professional advisors and consultants</li>
                <li>Law enforcement when required by law</li>
              </ul>

              <h4>4. Data Security</h4>
              <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>

              <h4>5. Your Rights</h4>
              <p>You have the right to:</p>
              <ul className="mb-30">
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your information</li>
                <li>Opt-out of marketing communications</li>
              </ul>

              <h4>6. Cookies and Tracking</h4>
              <p>We use cookies and similar tracking technologies to collect information about your browsing activities. You can control cookies through your browser settings.</p>

              <h4>7. Changes to This Policy</h4>
              <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

              <h4>8. Contact Us</h4>
              <p>If you have questions about this privacy policy, please contact us at:</p>
              <p>Email: privacy@acmeways.com</p>
              <p>Address: Toronto, Ontario, Canada</p>

              <p className="mt-50"><small>Last updated: January 2024</small></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Vie - Blog Dark</title>
    </>
  )
}

export default BlogDark;
