import React, { useState } from "react";
import { Link } from "gatsby";

const BlogStanderd = ({ blogs }) => {
  // Pagination settings
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 4; // Adjust this number as needed
  
  // Calculate total pages
  const totalPages = Math.ceil(blogs.length / postsPerPage);
  
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };

  // Generate page numbers array
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="blog-pg section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="posts">
              {/* Display current page posts */}
              {currentPosts.map((blogItem, index) => (
                <div 
                  className={`item ${index === currentPosts.length - 1 ? "" : "mb-80"}`} 
                  key={blogItem.id}
                >
                  <div className="img">
                    <Link to={`/blog/${blogItem.id}`}>
                      <img src={blogItem.image} alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <div className="row justify-content-center">
                      <div className="col-10">
                        <Link to={`/blog/${blogItem.id}`} className="date">
                          <span className="num">{blogItem.date.day}</span>
                          <span>{blogItem.date.month}</span>
                        </Link>
                        <div className="tags">
                          {blogItem.tags ? (
                            blogItem.tags.map((tag, index) => (
                              <Link key={index} to={`/blog/${blogItem.id}`}>
                                {tag}
                              </Link>
                            ))
                          ) : (
                            <span>No tags available</span> // Fallback if tags are undefined
                          )}
                        </div>
                        <h4 className="title">
                          <Link to={`/blog/${blogItem.id}`}>
                            {blogItem.title}
                          </Link>
                        </h4>
                        <p>{blogItem.content.intro}</p>
                        <Link to={`/blog/${blogItem.id}`} className="butn bord curve mt-30">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="pagination">
                  {/* Previous button */}
                  {currentPage > 1 && (
                    <span>
                      <a 
                        href="#0" 
                        onClick={(e) => {
                          e.preventDefault();
                          paginate(currentPage - 1);
                        }}
                      >
                        <i className="fas fa-angle-left"></i>
                      </a>
                    </span>
                  )}
                  
                  {/* Page numbers */}
                  {pageNumbers.map(number => (
                    <span 
                      key={number}
                      className={currentPage === number ? "active" : ""}
                    >
                      <a 
                        href="#0"
                        onClick={(e) => {
                          e.preventDefault();
                          paginate(number);
                        }}
                      >
                        {number}
                      </a>
                    </span>
                  ))}

                  {/* Next button */}
                  {currentPage < totalPages && (
                    <span>
                      <a 
                        href="#0" 
                        onClick={(e) => {
                          e.preventDefault();
                          paginate(currentPage + 1);
                        }}
                      >
                        <i className="fas fa-angle-right"></i>
                      </a>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogStanderd;
