import React from "react";
import { Link } from "gatsby";

const BlogDetails = ({ blog, theme }) => {
  if (!blog) return null;

  const {
    title,
    image,
    author,
    content,
    comments,
    tags
  } = blog;

  const renderSection = (section) => {
    switch (section.type) {
      case 'paragraph':
        return <p>{section.content}</p>;
      
      case 'heading':
        return <h6>{section.content}</h6>;
      
      case 'list':
        return (
          <ul>
            {section.items.map((item, index) => (
              <li key={index}>
                <span>{String(index + 1).padStart(2, '0')}</span> {item}
              </li>
            ))}
          </ul>
        );
      
      case 'images':
        return (
          <div className="row">
            {section.gallery.map((img, index) => (
              <div key={index} className="col-md-6">
                <div className="mb-10">
                  <img src={img} alt={`Gallery ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              <div className="img">
                <img src={image} alt={title} />
              </div>
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <h4 className="extra-title">{title}</h4>
                      
                      {/* Intro */}
                      <div className="spacial">
                        {content?.intro && <p>{content.intro}</p>}
                      </div>

                      {/* Dynamic Sections */}
                      {content && content.sections ? (
                        content.sections.map((section, index) => (
                          <div key={index}>
                            {renderSection(section)}
                          </div>
                        ))
                      ) : (
                        <div>No content available.</div>
                      )}

                      {/* Quote */}
                      {content && content.quote && (
                        <div className="quotes text-center">
                          <p>{content.quote}</p>
                        </div>
                      )}

                      {/* Social Share */}
                      <div className="share-info">
                        <div className="social">
                          {author && author.social && Object.entries(author.social).map(([platform, link]) => (
                            <a key={platform} href={link}>
                              <i className={`fab fa-${platform}`}></i>
                            </a>
                          ))}
                        </div>
                        <div className="tags">
                          {tags && tags.length > 0 ? (
                            tags.map((tag, index) => (
                              <React.Fragment key={index}>
                                <a href="#0">{tag}</a>
                                {index < tags.length - 1 && ','}
                              </React.Fragment>
                            ))
                          ) : (
                            <span>No tags available.</span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Author Info */}
                    <div className="author">
                      <div className="author-img">
                        {author && author.image ? (
                          <img src={author.image} alt={author.name} />
                        ) : (
                          <img src="path/to/default-image.jpg" alt="Default Author" />
                        )}
                      </div>
                      <div className="info">
                        <h6><span>author :</span> {author ? author.name : 'Unknown Author'}</h6>
                        <p>{author ? author.bio : 'No bio available.'}</p>
                        <div className="social">
                          {author && author.social && Object.entries(author.social).map(([platform, link]) => (
                            <a key={platform} href={link}>
                              <i className={`fab fa-${platform}`}></i>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination">
                <span>
                  <a href="#0">Prev Post</a>
                </span>
                <span className="icon">
                  <Link to="/blog">
                    <i className="fas fa-th-large"></i>
                  </Link>
                </span>
                <span className="text-right">
                  <a href="#0">Next Post</a>
                </span>
              </div>

              {/* Display existing comments only */}
              {comments && comments.length > 0 && (
                <div className="comments-area">
                  <h5>Comments :</h5>
                  {comments.map((comment, index) => (
                    <div key={index} className={`item ${index === 1 ? 'relped' : ''}`}>
                      <div className="comment-img">
                        <img src={comment.avatar} alt={comment.author} />
                      </div>
                      <div className="info">
                        <h6>
                          {comment.author} - <span>{comment.date}</span>
                        </h6>
                        <p>{comment.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
